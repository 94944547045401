import React from "react";
import HeroSection from "../../Components/HeroSection/HeroSection";
// import MenuBar from "../../Components/MenuBar/MenuBar";

const Home = () => {
  return (
    <>
      {/* <MenuBar /> */}
      <HeroSection />
    </>
  );
};

export default Home;
