import React from "react";
import "./heroSection.css";
import data from "../../data";
import Typewriter from "typewriter-effect";

const HeroSection = () => {
  const personalInfo = data.personal_information;

  return (
    <div className="hero-section">
      <div className="hero-description">
        <div className="hero-inner">
          <h3 className="text">Hello, I'm</h3>
          <div className="name">{personalInfo.name}</div>
          <div className="tagline">
          <Typewriter
              options={{
                strings: personalInfo.tagline,
                autoStart: true,
                loop: true
              }}
          />
          </div>
          
          <button className="hero-button">
            <span><a href={personalInfo.cv} download>DOWNLOAD CV</a></span>
          </button>
        </div>
      </div>
      <div className="hero-image">
        <img src={personalInfo.profile} alt="heroimage" />
      </div>
    </div>
  );
};

export default HeroSection;
