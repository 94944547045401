import profile from '../src/assets/image/jay.jpg'
import CV from './assets/cv.pdf'

const data = {
    'personal_information': {
         name: 'Jay Nath Thakur',
         tagline: ['Backend Developer', 'Node.js', 'Express.js', 'MongoDB', 'React.js'],
         cv: CV,
         description: 'this is the test description',
         profile: profile,
    },
    'skills': {
        results: [
            {
                title: 'html',
                known_percentage: 90,
            },
            {
                title: 'css',
                known_percentage: 80,
            },
            {
                title: 'javascript',
                known_percentage: 60,
            },
            {
                title: 'react',
                known_percentage: 50,
            }
        ]
    }
}
export default data;